import lang from '@/utils/Language';
import properties from '@/assets/i18n/properties/en.json';
import es_properties from '@/assets/i18n/properties/es.json';
import tags from '@/assets/i18n/tags/en.json';
import es_tags from '@/assets/i18n/tags/es.json';
import ui from '@/assets/i18n/ui/en.json';
import es_ui from '@/assets/i18n/ui/es.json';

const en = {
  pro: {
    'Who is looking for help?': 'Who is looking for help?',
    'What do you need help with?': 'What does your client need help with?',
    'What type of eviction notice have you received?': 'What type of eviction notice has your client received?',
    'My landlord told me verbally': 'Their landlord told them verbally',
    'Do you identify with any of these groups?': 'Does your client identify with any of these groups?',
    'Optional. Select all that apply to you or someone in your household.':
      "<p><b>Optional.</b> Select all that apply to your client.</p><p>Your answers are confidential and will not be saved or shared. They allow us to recommend resources to meet your client's unique needs.</p>",
    'Where would you like to find resources?': 'Where would your client like to find resources?',
    'Required. This will allow us to recommend resources in your area.':
      "<b>Required.</b> This will allow us to recommend resources in your client's area.",
    'Note: Information about your location will not be saved or shared.':
      "Information about your client's location will not be saved or shared. This site focuses on the nine counties of the Bay Area. Bay Area region includes Alameda, Contra Costa, Marin, Napa, San Francisco, San Mateo, Santa Clara, Solano, and Sonoma counties.",

    'Please select one. Your selection will allow us to recommend resources that fit your situation.':
      "<b>Please select one.</b> Your selection will allow us to recommend resources that fit your client's situation.",
    "I've received an eviction notice": "They've received an eviction notice",
    'I need help paying my rent or mortgage': 'They need help paying their rent or mortgage',
    'My house is going to be foreclosed': 'Their house is going to be foreclosed',
    "I'm having a problem with my landlord/master tenant/roommate":
      "They're having a problem with their landlord/roommate/another person to whom they pay rent.",
    'I don’t know / prefer not to say': 'They don’t know / prefer not to say'
  },
  self: {
    'Who is looking for help?': 'Who is looking for help?',
    'What do you need help with?': 'What do you need help with?',
    'What type of eviction notice have you received?': 'What type of eviction notice have you received?',
    'My landlord told me verbally': 'My landlord told me verbally',
    'Do you identify with any of these groups?': 'Do you identify with any of these groups?',
    'Optional. Select all that apply to you or someone in your household.':
      '<p><b>Optional.</b> Select all that apply to you or someone in your household.</p><p>Your answers are confidential and will not be saved or shared. They allow us to recommend resources to meet your unique needs.</p>',
    'Where would you like to find resources?': 'Where would you like to find resources?',
    'Required. This will allow us to recommend resources in your area.': '<b>Required.</b> This will allow us to recommend resources in your area.',
    'Note: Information about your location will not be saved or shared.':
      'Information about your location will not be saved or shared. This site focuses on the 9 counties of the Bay Area.',

    'Please select one. Your selection will allow us to recommend resources that fit your situation.':
      '<b>Please select one.</b> Your selection will allow us to recommend resources that fit your situation.',
    "I've received an eviction notice": "I've received an eviction notice",
    'I need help paying my rent or mortgage': 'I need help paying my rent or mortgage',
    'My house is going to be foreclosed': 'My house is going to be foreclosed',
    "I'm having a problem with my landlord/master tenant/roommate":
      "I'm having a problem with my landlord/roommate/another person to whom I pay rent.",
    'I don’t know / prefer not to say': 'I don’t know / prefer not to say'
  },
  'Stay Housed Bay Area': 'Stay Housed Bay Area',
  'Helpful Links': 'Helpful Links',
  'Myself or my family': 'Myself or my family',
  'My client': 'My client',
  'What do you need help with?': 'What do you need help with?',
  'Note: In California, only a sheriff can physically evict someone.':
    "In California, only a sheriff can physically evict someone. Even if a tenant is months behind on rent, the landlord cannot directly evict a tenant or get rid of a tenant's belongings. The landlord must get a court order for the sheriff to evict a tenant.",
  Question: 'Question',
  None: 'None',
  'Written 3-day notice to pay rent': 'Written 3-day notice to pay rent',
  'Written 30, 60, or 90-day notice to move out': 'Written 30, 60, or 90-day notice to move out',
  'Official court papers': 'Official court papers',
  'Sheriff’s notice to vacate': 'Sheriff’s notice to vacate',
  'Immigrant or refugee': 'Immigrant or refugee (with or without legal documentation)',
  'Parent with children in the household or pregnant': 'Parent with children in the household or pregnant',
  'Person experiencing domestic violence or intimate partner violence': 'Person experiencing domestic violence or intimate partner violence',
  'Person living with HIV or AIDS': 'Person living with HIV or AIDS',
  'Person with disabilities': 'Person with disabilities',
  'Senior citizen (65+ years old)': 'Senior citizen (65+ years old)',
  Veteran: 'Veteran',
  'Youth (Under 25 years old)': 'Youth (Under 25 years old)',
  'None of these': 'None of these',
  'Use location': 'Use location',
  'Use map': 'Use map',
  'Address or city or zip code': 'Address or city or zip code',
  'Click or tap on an area on the map to select': 'Click or tap on an area on the map to select',
  'Automatically find my location': 'Automatically find my location',
  'Previous Question': 'Previous Question',
  'See results': 'See results',
  'x categories': '${categories} categories',
  '*Note: The resources on this site provide support to people at risk of losing their current housing.':
    'The resources on this site provide support to people at risk of losing their current housing.',
  'searching place for':
    "<p><b>If you have lost your housing and don’t have a place to stay,</b> <a href='#experiencing-homelessness'>check out these other resources</a>.</p><p><b>If you are searching for a new place to live,</b> <a href='#affordable-housing'>see affordable housing listings here</a>.</p>",
  'Show only resources with no citizenship or legal resident requirement': 'Show only resources with no citizenship or legal resident requirement',
  Restart: 'Restart',
  'Change location': 'Change location',
  'What to do next': 'What to do next',
  'One Degree features': 'One Degree features',
  'Close details': 'Close details',
  'See More Results': 'See More Results',
  'Confirm location': 'Confirm location',
  'Update location': 'Update location',
  'Get help if you are experiencing homelessness': 'Get help if you are experiencing homelessness',
  'If you have lost your housing and/or don’t have a place to stay, contact your county as soon as you can for assistance.':
    'If you have lost your housing and/or don’t have a place to stay, contact your county as soon as you can for assistance. <b>Start with these resource collections</a> (you will be redirected to the One Degree website).',
  'You can also call 211 for assistance':
    '<b>You can also call 211 for assistance.</b> Trained operators can assist you to find resources such as short-term shelters and more.',
  'Resource Collections': 'Resource Collections',
  'Get support for affordable housing': 'Get support for affordable housing',
  'If you are looking for an affordable place to live in the Bay Area, check out the new Doorway Housing Portal.':
    'If you are looking for an affordable place to live in the Bay Area, check out the new <a href="https://housingbayarea.mtc.ca.gov/" target="_blank"><b>Doorway Housing Portal {{popoutIcon}}</b></a>.',
  'The site has affordable housing listings that you can search with filters and a map-based tool.':
    "The site has affordable housing listings that you can search with filters and a map-based tool. Once it's fully up and running, the site will have listings of various types from across the region. It also includes information to guide you through the process of finding and applying for affordable housing.",
  'The portal is accessible via desktop and mobile in five languages: English, Traditional Chinese, Spanish, Vietnamese, and Tagalog.':
    'The portal is accessible via desktop and mobile in five languages: English, Traditional Chinese, Spanish, Vietnamese, and Tagalog.',
  'Thank you! Based on your answer, we recommend these resources': 'Thank you! Based on your answer, we recommend these resources',
  'If you would like to save these resources and search for other resources, you can':
    'If you would like to save these resources and search for other resources, you can',
  'create a free One Degree account.': 'create a free One Degree account.',
  'Things to consider': 'Things to consider',
  '<b>Open your mail</b> If you are in the eviction process or may be soon, it is very important that you check your mail regularly and open everything that you receive. You may be required to respond within just a few days. It is also important that you attend any hearings that are scheduled in your case.':
    '<b>Open your mail</b> If you are in the eviction process or may be soon, it is very important that you check your mail regularly and open everything that you receive. You may be required to respond within just a few days. It is also important that you attend any hearings that are scheduled in your case.',
  '<b>Get legal help</b> If you have received any official eviction notices or papers—such as a 60-day notice, a 30-day notice, a 3-day notice—it is very important to respond and get legal help as soon as possible. Failure to respond as instructed could make it impossible to defend yourself. Tenants with legal counsel are much more likely to be able to stay in their homes.':
    '<b>Get legal help</b> If you have received any official eviction notices or papers—such as a 60-day notice, a 30-day notice, a 3-day notice—it is very important to respond and get legal help as soon as possible. Failure to respond as instructed could make it impossible to defend yourself. Tenants with legal counsel are much more likely to be able to stay in their homes.',
  '<b>You have rights</b> Tenants have rights and you don’t have to face challenges alone. You can connect to tenant unions and other advocates to demand your rights, improve your living conditions, and fight for housing justice. Alone you may be vulnerable, but together tenants are powerful.':
    '<b>You have rights</b> Tenants have rights and you don’t have to face challenges alone. You can connect to tenant unions and other advocates to demand your rights, improve your living conditions, and fight for housing justice. Alone you may be vulnerable, but together tenants are powerful.',
  'All Home | Frequently Asked Questions': 'All Home | Frequently Asked Questions',
  'Can I get help or resources directly from Stay Housed Bay Area?': 'Can I get help or resources directly from Stay Housed Bay Area?',
  'Stay Housed Bay Area is a free, user-friendly website where':
    "Stay Housed Bay Area is a free, user-friendly website where residents of the nine Bay Area counties can search for and access resources to get the help they need to keep their housing. This tool will help you find community resources to stay in your home, respond to an eviction notice, and/or avoid losing your housing. It is not designed to provide listings of affordable or available rental housing, though those resources can be accessed through the new <a href='https://housingbayarea.mtc.ca.gov/' target='_blank'>Doorway Housing Portal {{popoutIcon}}</a>.",
  'How do I suggest an update to a resource listed on this site or the addition of a new resource to the site?':
    'How do I suggest an update to a resource listed on this site or the addition of a new resource to the site?',
  'We appreciate any updated information or additional resources':
    "<p>We appreciate any updated information or additional resources users may be able to provide. To submit information, please use the “Suggest edit” function when looking at a result or email <a href='mailto:info@1degree.org' target='_blank'>info@1degree.org</a></p>",
  'I have already lost my housing, how can I find help now?': 'I have already lost my housing, how can I find help now?',
  'If you lost your housing, are staying with friends/family or in a vehicle':
    "If you lost your housing, are staying with friends/family or in a vehicle, you may be eligible for homelessness assistance. <a href='https://www.1degree.org/collections/10376-bay-area-coordinated-entry-access-points-san-francisco-bay-area-california-usa' target='_blank'>Look here {{popoutIcon}}</a> for help, or you can also call 211 for assistance.",
  'I need to find a new place to live. How do I find an affordable apartment or get on a waitlist for one?':
    'I need to find a new place to live. How do I find an affordable apartment or get on a waitlist for one?',
  'To find affordable housing, please visit':
    "To find affordable housing, please visit <a href='https://housingbayarea.mtc.ca.gov/' target='_blank'>Doorway Housing Portal {{popoutIcon}}</a>",
  'Is the information I enter on this site secure? Do you ever save or share it?':
    'Is the information I enter on this site secure? Do you ever save or share it?',
  'Your answers to questions are confidential and will not be saved':
    '<p>Your answers to questions are confidential and will not be saved or shared. Creating an account is optional, so you do not need to provide any personal information in order to view resources through Stay Housed Bay Area. If you do provide personal information or store your information on this website, we will never share your information without your permission.</p><p>We track user behavior as a whole to understand how people are using the site and how we can improve it, but we do not use any personal information to do this.</p><p>If you create an account, we may contact you to learn more about your user experience.</p>',
  'What features are available if I create a free One Degree account for myself?':
    'What features are available if I create a free One Degree account for myself?',
  'If you create a free One Degree account, you will have a private':
    '<p>If you create a free One Degree account, you will have a private, protected portal to save resources, create referral lists for clients, and more. This can be especially useful if you cannot or do not want to access and store hard copies of documents and information.</p><p>Creating an account makes it easier to save your resources, but it is not required. Your personal information will not be shared without your consent.</p>',
  'Who funds and runs Stay Housed Bay Area?': 'Who funds and runs Stay Housed Bay Area?',
  'This website was created by All Home and One Degree':
    'This website was created by All Home and One Degree, with support and advice from Health Leads. All Home is a Bay Area organization advancing solutions that disrupt the cycles of poverty and homelessness, redress racial disparities, and create more economic mobility opportunities for people with extremely low incomes. Learn more on the <a href="/stayhousedbayarea/about">About page</a>.',
  'All Home | About': 'All Home | About',
  'All Home | Helpful Links': 'All Home | Helpful Links',
  'Helpful Links': 'Helpful Links',
  'Stay Housed Bay Area Launch Team': 'Stay Housed Bay Area Launch Team',
  'Stay Housed Bay Area is a free, user-friendly website':
    '<p>Stay Housed Bay Area is a free, user-friendly website where residents of the nine Bay Area counties can search for and access resources to get the help you need to keep your housing. These resources can help you <b>pay your rent, respond to an eviction notice, or assert your rights so you can stay in your current housing.</b></p><p>This site is NOT designed to provide listings of affordable or available rental housing, <a href="/stayhousedbayarea/assessment#affordable-housing" target="_blank">those resources are available here {{popoutIcon}}</a>.</p><p>This website was created by All Home and One Degree, with support and advice from Health Leads.</p><p><b>Do you want to share the site with your networks?</b><br><a href="/stayhousedbayarea/sitebadge">Check out our outreach toolkit and graphics for your website.</a></p>',
  'Who We Are': 'Who We Are',
  'All Home is a Bay Area organization advancing solutions that disrupt the cycles of poverty and homelessness':
    "<p><a href='https://www.allhomeca.org/' target='_blank'><b>All Home {{popoutIcon}}</b></a> is a Bay Area organization advancing solutions that disrupt the cycles of poverty and homelessness, redress racial disparities, and create more economic mobility opportunities for people with extremely low incomes.</p>",
  'One Degree brings life-changing social services within reach for the millions':
    "<p><a href='https://about.1degree.org/' target='_blank'><b>One Degree {{popoutIcon}}</b></a> brings life-changing social services within reach for the millions of low-income people seeking help everyday. Through easy-to-use technology and deep community partnership, they remove the formidable barriers that prevent people from getting everything from housing to healthcare and beyond.</p>",
  'Health Leads is an innovation hub that seeks to unearth and address':
    "<p><a href='https://healthleadsusa.org/' target='_blank'><b>Health Leads {{popoutIcon}}</b></a> is an innovation hub that seeks to unearth and address the deep societal roots of racial inequity that impact our health. Today, Health Leads works both nationally and locally, across the U.S., to build partnerships and redesign systems so every person, in every community, can live with health, well-being and dignity.</p>",
  'Questions?': 'Questions?',
  'Questions about the website? Check the FAQ page or':
    "<p>Check the <a href='/stayhousedbayarea/faqs'>FAQ page</a> or email us at <a href='mailto:help@1degree.org' target='_blank'><b>help@1degree.org</b></a>.</p><p><i>Please note that our staff are unable to directly resolve or address individuals’ housing challenges. If you need to talk to a professional, use the resources on this site or call 211.</i></p>",
  'Below are resources that provide information about how you can stay in your home':
    'Below are resources that provide information about how you can stay in your home and better understand your rights. Unlike the resources you’ll find with the Stay Housed Bay Area search tool, these links do not provide direct legal or financial assistance. They are informational only.',
  'Tenant Power Toolkit': "<a href='https://tenantpowertoolkit.org/' target='_blank'>Tenant Power Toolkit {{popoutIcon}}</a>",
  'Features a detailed guide to help tenants understand and respond to the eviction process':
    'This toolkit features a detailed guide to help tenants understand and respond to the eviction process, and information on tenant rights and organizing.',
  'Legal Help FAQs': "<a href='https://legalfaq.org/' target='_blank'>Legal Help FAQs {{popoutIcon}}</a>",
  'Allows tenants to search for local legal rules about housing, eviction, rent, and':
    'Tenants can search for local legal rules about housing, eviction, rent, and landlord-tenant issues. You can also find groups and guides to help with housing assistance.',
  'Tenants Together’s Resource Directory':
    "<a href='https://www.tenantstogether.org/resource-directory' target='_blank'>Tenants Together’s Resource Directory {{popoutIcon}}</a>",
  'Has a statewide list of tools to defend and advance the rights of California tenants':
    "A statewide list of tools to defend and advance the rights of California tenants to safe, decent, and affordable housing. One of these tools is a <a href='https://www.tenantstogether.org/resources/list-rent-control-ordinances-city' target='_blank'>list of California cities {{popoutIcon}}</a> that have rent control ordinances.",
  'Tenant Protections': "<a href='https://tenantprotections.org/' target='_blank'>Tenant Protections {{popoutIcon}}</a>",
  'Provides renters in California with high quality information about their rights under':
    'This site provides high-quality information for California renters about their rights under both California law and local ordinances. It features a tool to determine if you are covered by tenant protection laws and if a rent increase is legal or not.',
  'Trouble with your landlord?': 'Trouble with your landlord?</br>Behind on rent?</br>At risk of eviction?',
  'We all need help sometimes.': "<b style='font-weight: 600'>We all need help sometimes.<br>Resources are available.</b>",
  'Find a resource that meets your needs': 'Find a resource that meets your needs',
  'No sign up or account required':
    '<b>* No sign up or account required.</b> Your personal information will not be saved or shared without your consent.',
  'Stay Housed Bay Area connects people':
    '<b>Stay Housed Bay Area</b> connects people with the help they need to keep their housing, like rental assistance and legal services.',
  'Brought to you by': 'Brought to you by',
  'Do you need a place to live right now?': 'Do you need a place to live right now?',
  'Have you lost your housing, and/or are you staying with friends, family or in a vehicle':
    'Have you lost your housing, and/or are you staying with friends, family or in a vehicle? You may be eligible for homelessness assistance. This site isn’t focused on that situation, but you can start here to get help:',
  'Get help now': 'Get help now',
  "If you have lost your housing and don't have a place to stay, contact your county":
    "If you have lost your housing and don't have a place to stay, contact your county as soon as you can for housing assistance.",
  'Find by County': 'Find by County',
  'Call 211': 'Call 211',
  'Call 211 for assistance': 'Call 211 for assistance',
  'Trained operators can assist you to find resources such as short-term shelters and more.':
    'Trained operators can assist you to find resources such as short-term shelters and more.',
  'How to use this site': 'How to use this site',
  'Tell us about your needs': 'Tell us about your needs',
  'Answer a few simple questions about your housing situation': 'Answer a few simple questions about your housing situation',
  'See your resources': 'See your resources',
  'Get a list of recommended resources and service providers in your area, tailored to your needs':
    'Get a list of recommended resources and service providers in your area, tailored to your needs',
  'Connect with your resources': 'Connect with your resources',
  'Reach out to your recommended service providers to get the support you need to stay in your home':
    'Reach out to your recommended service providers to get the support you need to stay in your home',
  'It looks like you are outside the Bay Area': 'It looks like you are outside the Bay Area',
  'Stay Housed Bay Area serves the nine counties of the San Francisco Bay Area':
    'Stay Housed Bay Area serves the nine counties of the San Francisco Bay Area (Alameda, Contra Costa, Marin, Napa, San Francisco, San Mateo, Santa Clara, Solano y Sonoma). If you are outside of this region and need housing assistance, you can search or browse resources on <a href="https://www.1degree.org">One ​​Degree.</a>',
  'Do you live in California outside of the Bay Area?': 'Do you live in California outside of the Bay Area?',
  'Below is a Collection of housing resources available anywhere in California. You will be redirected to the One Degree website.':
    'Below is a Collection of housing resources available anywhere in California. You will be redirected to the One Degree website.',
  'Stay Housed - California Resources': 'Stay Housed - California Resources',
  'Read more': 'Read more',
  'Show less': 'Show less',
  'Please enter a more specific location. Try entering an address, city, or zip code.':
    'Please enter a more specific location. Try entering an address, city, or zip code.',
  'All Home | Share Stay Housed Bay Area': 'All Home | Share Stay Housed Bay Area',
  'Share Stay Housed Bay Area': 'Share Stay Housed Bay Area',
  'Share SHBA': 'Share SHBA',
  'Outreach Toolkit': 'Outreach Toolkit',
  'Site Badge': 'Site Badge',
  'Use this Stay Housed Bay Area Outreach Toolkit to share about the new website on social media':
    'Use this Stay Housed Bay Area Outreach Toolkit to share about the new website on social media, in your organization’s next email, or with your community. The toolkit includes graphics, sample posts, newsletter and web content, printable materials, and more.<br><a href="https://docs.google.com/document/d/1ZlPXWi_QsoAvHheWIul3hHTjmBHXeLvrbCe5e5cIQEg/edit?usp=sharing" target="_blank">Outreach Toolkit (Google Drive)</a>',
  'Add a Stay Housed Bay Area badge to your website so your community can easily find resources to help them keep their housing':
    '<p>Add a Stay Housed Bay Area badge to your website so your community can easily find resources to help them keep their housing.</p><p><b>Option 1:</b> Copy and paste an embed code and place it on your website.<br><b>Option 2:</b> <a href="https://s3.us-west-1.amazonaws.com/odas.production/allHome/badges/badges.zip" target="_blank">Download all badges (ZIP)</a></p>',
  'Paste this code': 'Paste this code',
  'Recommended Resource': 'Recommended Resource',
  'This resource was recommended by our team because it currently serves many people in your county.':
    'This resource was recommended by our team because it currently serves many people in your county.'
};

const es = {
  pro: {
    'Who is looking for help?': '¿Quién está buscando ayuda?',
    'What do you need help with?': '¿Con qué necesita ayuda su cliente?',
    'What type of eviction notice have you received?': '¿Qué tipo de aviso de desalojo ha recibido su cliente?',
    'My landlord told me verbally': 'Su arrendador se lo dijo verbalmente',
    'Do you identify with any of these groups?': '¿Su cliente se identifica con alguno de estos grupos?',
    'Optional. Select all that apply to you or someone in your household.':
      '<p><b>Opcional.</b> Seleccione todas las que apliquen a su cliente.</p><p>Sus respuestas son confidenciales y no se guardarán ni compartirán. Nos permiten recomendar recursos para satisfacer las necesidades únicas de su cliente.</p>',
    'Where would you like to find resources?': '¿Dónde desea encontrar recursos su cliente?',
    'Required. This will allow us to recommend resources in your area.':
      '<b>Requerido.</b> Esto nos permitirá recomendar recursos en el área de su cliente.',
    'Note: Information about your location will not be saved or shared.':
      'La información sobre la ubicación de su cliente no se guardará ni se compartirá. Este sitio se enfoca en los nueve condados del Área de la Bahía. La región del Área de la Bahía incluye los condados de Alameda, Contra Costa, Marin, Napa, San Francisco, San Mateo, Santa Clara, Solano y Sonoma.',
    'Please select one. Your selection will allow us to recommend resources that fit your situation.':
      '<b>Seleccione uno.</b> Su selección nos permitirá recomendar recursos que se ajusten a la situación de su cliente.',
    "I've received an eviction notice": 'Ha recibido un aviso de desalojo',
    'I need help paying my rent or mortgage': 'Necesita ayuda para pagar su alquiler o hipoteca',
    'My house is going to be foreclosed': 'Su casa va a ser embargada',
    "I'm having a problem with my landlord/master tenant/roommate":
      'Tiene un problema con su arrendador/compañero de cuarto/otra persona a quien le paga el alquiler.',
    'I don’t know / prefer not to say': 'No sabe / prefiere no decir'
  },
  self: {
    'Who is looking for help?': '¿Quién está buscando ayuda?',
    'What do you need help with?': '¿Con qué necesita ayuda?',
    'What type of eviction notice have you received?': '¿Qué tipo de aviso de desalojo ha recibido?',
    'My landlord told me verbally': 'Mi arrendador me lo dijo verbalmente',
    'Do you identify with any of these groups?': '¿Se identifica con alguno de estos grupos?',
    'Optional. Select all that apply to you or someone in your household.':
      '<p><b>Opcional.</b> Seleccione todas las que apliquen a usted o alguien de su hogar.</p><p>Sus respuestas son confidenciales y no se guardarán ni compartirán. Nos permiten recomendar recursos para satisfacer sus necesidades únicas.</p>',
    'Where would you like to find resources?': '¿Dónde desea encontrar recursos?',
    'Required. This will allow us to recommend resources in your area.': '<b>Requerido.</b> Esto nos permitirá recomendar recursos en su área.',
    'Note: Information about your location will not be saved or shared.':
      'La información sobre su ubicación no se guardará ni se compartirá. Este sitio se enfoca en los 9 condados del Área de la Bahía.',
    'Please select one. Your selection will allow us to recommend resources that fit your situation.':
      '<b>Seleccione uno.</b> Su selección nos permitirá recomendar recursos que se ajusten a su situación.',
    "I've received an eviction notice": 'He recibido un aviso de desalojo',
    'I need help paying my rent or mortgage': 'Necesito ayuda para pagar mi alquiler o hipoteca',
    'My house is going to be foreclosed': 'Mi casa va a ser embargada',
    "I'm having a problem with my landlord/master tenant/roommate":
      'Tengo un problema con mi arrendador/compañero de cuarto/otra persona a quien le pago el alquiler.',
    'I don’t know / prefer not to say': 'No sé / prefiero no decir'
  },
  'Stay Housed Bay Area': 'Stay Housed Bay Area',
  'Helpful Links': 'Enlaces útiles',
  'Myself or my family': 'Mí mismo o mi familia',
  'My client': 'Mi cliente',
  'What do you need help with?': '¿Con qué necesita ayuda?',
  'Note: In California, only a sheriff can physically evict someone.':
    'En California, solo un sheriff puede desalojar físicamente a alguien. Incluso si un inquilino tiene meses de retraso en el pago del alquiler, el arrendador no puede desalojarlo directamente ni deshacerse de sus pertenencias. El arrendador debe obtener una orden judicial para que el sheriff desaloje al inquilino.',
  Question: 'Pregunta',
  None: 'Ninguno',
  'Written 3-day notice to pay rent': 'Aviso por escrito de 3 días para pagar el alquiler',
  'Written 30, 60, or 90-day notice to move out': 'Aviso por escrito de 30, 60 o 90 días para mudarse',
  'Official court papers': 'Documentos judiciales oficiales',
  'Sheriff’s notice to vacate': 'Aviso del sheriff para desalojar',
  'Immigrant or refugee': 'Inmigrante o refugiado (con o sin documentación legal)',
  'Parent with children in the household or pregnant': 'Padre/madre con hijos en el hogar o embarazada',
  'Person experiencing domestic violence or intimate partner violence': 'Persona que sufre violencia doméstica o violencia de pareja',
  'Person living with HIV or AIDS': 'Persona que vive con VIH o SIDA',
  'Person with disabilities': 'Persona con discapacidad',
  'Senior citizen (65+ years old)': 'Tercera edad (65+ años)',
  Veteran: 'Veterano',
  'Youth (Under 25 years old)': 'Joven (menor de 25 años)',
  'None of these': 'Ninguna de las anteriores',
  'Use location': 'Usar ubicación',
  'Use map': 'Usar mapa',
  'Address or city or zip code': 'Dirección o ciudad o código postal',
  'Click or tap on an area on the map to select': 'Haga clic o toque un área del mapa para seleccionar',
  'Automatically find my location': 'Encontrar mi ubicación automáticamente',
  'Previous Question': 'Pregunta anterior',
  'See results': 'Ver resultados',
  'x categories': '${categories} categorías',
  '*Note: The resources on this site provide support to people at risk of losing their current housing.':
    'Los recursos en este sitio brindan apoyo a las personas en riesgo de perder su vivienda actual.',
  'searching place for':
    "<p><b>Si ha perdido su vivienda y no tiene un lugar donde quedarse,</b> <a href='#experiencing-homelessness'>consulte estos otros recursos</a>.</p><p><b>Si está buscando un nuevo lugar para vivir,</b> <a href='#affordable-housing'>consulte los listados de viviendas asequibles aquí</a>.</p>",
  'Show only resources with no citizenship or legal resident requirement': 'Mostrar solo recursos sin requisito de ciudadanía o residencia legal',
  Restart: 'Reanudar',
  'Share all': 'Compartir todo',
  'Change location': 'Cambiar ubicación',
  'What to do next': 'Qué hacer ahora',
  'One Degree features': 'Funciones de One Degree',
  'Close details': 'Cerrar detalles',
  'See More Results': 'Ver más resultados',
  'Confirm location': 'Confirmar ubicación',
  'Update location': 'Actualizar ubicación',
  'Get help if you are experiencing homelessness': 'Obtenga ayuda si se encuentra sin hogar',
  'If you have lost your housing and/or don’t have a place to stay, contact your county as soon as you can for assistance.':
    'Si ha perdido su vivienda y/o no tiene un lugar donde quedarse, comuníquese con su condado lo antes posible para obtener ayuda. <b>Comience con estas colecciones de recursos</a> (será redirigido al sitio web de One Degree).',
  'You can also call 211 for assistance':
    '<b>También puede llamar al 211 para obtener ayuda.</b> Los operadores capacitados pueden ayudarlo a encontrar recursos como refugios temporales y más.',
  'Resource Collections': 'Colecciones de recursos',
  'Get support for affordable housing': 'Obtenga apoyo en viviendas asequibles',
  'If you are looking for an affordable place to live in the Bay Area, check out the new Doorway Housing Portal.':
    'Si está buscando un lugar asequible para vivir en el Área de la Bahía, consulte el nuevo <a href="https://housingbayarea.mtc.ca.gov/" target="_blank"><b>Doorway Housing Portal {{popoutIcon}}</b></a>.',
  'The site has affordable housing listings that you can search with filters and a map-based tool.':
    'El sitio tiene listados de viviendas asequibles en donde puede buscar con filtros y una herramienta basada en mapas. Una vez que esté en pleno funcionamiento, el sitio tendrá diversos tipos de listados de toda la región. También incluye información para guiarlo a través del proceso de búsqueda y aplicación de viviendas asequibles.',
  'The portal is accessible via desktop and mobile in five languages: English, Traditional Chinese, Spanish, Vietnamese, and Tagalog.':
    'Se puede acceder al portal a través de computadoras de escritorio y dispositivos móviles en cinco idiomas: inglés, chino tradicional, español, vietnamita y tagalo.',
  'Thank you! Based on your answer, we recommend these resources': '¡Gracias! Con base en su respuesta, recomendamos estos recursos',
  'If you would like to save these resources and search for other resources, you can':
    'Si desea guardar estos recursos y buscar otros recursos, puede',
  'create a free One Degree account.': 'crear una cuenta gratuita en One Degree.',
  'Things to consider': 'Aspectos a tener en cuenta',
  '<b>Open your mail</b> If you are in the eviction process or may be soon, it is very important that you check your mail regularly and open everything that you receive. You may be required to respond within just a few days. It is also important that you attend any hearings that are scheduled in your case.':
    '<b>Abra su correo</b> Si está en proceso de desalojo o puede estarlo pronto, es muy importante que revise su correo regularmente y abra todo lo que reciba. Es posible que tenga que responder dentro de pocos días. También es importante que asista a las audiencias programadas para su caso.',
  '<b>Get legal help</b> If you have received any official eviction notices or papers—such as a 60-day notice, a 30-day notice, a 3-day notice—it is very important to respond and get legal help as soon as possible. Failure to respond as instructed could make it impossible to defend yourself. Tenants with legal counsel are much more likely to be able to stay in their homes.':
    '<b>Obtenga ayuda legal</b> Si ha recibido algún aviso o documento oficial de desalojo, como un aviso de 60 días, un aviso de 30 días, un aviso de 3 días, es muy importante que responda y obtenga ayuda legal lo antes posible. No responder según las instrucciones podría hacer que sea imposible defenderse. Es mucho más probable que los inquilinos con asesoría legal puedan quedarse en sus casas.',
  '<b>You have rights</b> Tenants have rights and you don’t have to face challenges alone. You can connect to tenant unions and other advocates to demand your rights, improve your living conditions, and fight for housing justice. Alone you may be vulnerable, but together tenants are powerful.':
    '<b>Tiene derechos</b> Los inquilinos tienen derechos y no tiene que enfrentar los desafíos solo. Puede conectarse con sindicatos de inquilinos y otros defensores para exigir sus derechos, mejorar sus condiciones de vida y luchar por la justicia de vivienda. Solo puede ser vulnerable, pero juntos los inquilinos son poderosos.',
  'All Home | Frequently Asked Questions': 'Todo Inicio | Preguntas frecuentes',
  'Can I get help or resources directly from Stay Housed Bay Area?': '¿Puedo obtener ayuda o recursos directamente de Stay Housed Bay Area?',
  'Stay Housed Bay Area is a free, user-friendly website where':
    "Stay Housed Bay Area es un sitio web gratuito y fácil de usar donde los residentes de los nueve condados del Área de la Bahía pueden buscar y acceder a recursos para obtener la ayuda que necesitan para conservar su vivienda. Esta herramienta lo ayudará a encontrar recursos comunitarios para permanecer en su hogar, responder a un aviso de desalojo y/o evitar perder su vivienda. No está diseñado para proporcionar listados de viviendas de alquiler asequibles o disponibles, pero puede acceder a esos recursos a través del nuevo <a href='https://housingbayarea.mtc.ca.gov/' target='_blank'>Doorway Housing Portal {{popoutIcon}}</a>.",
  'How do I suggest an update to a resource listed on this site or the addition of a new resource to the site?':
    '¿Cómo puedo sugerir una actualización de un recurso listado en este sitio o la adición de un nuevo recurso al sitio?',
  'We appreciate any updated information or additional resources':
    "<p>Agradecemos cualquier información actualizada o recursos adicionales que los usuarios puedan proporcionar. Para enviar información, utilice la función \"Sugerir edición\" cuando vea un resultado o envíe un correo electrónico a <a href='mailto:info@1degree.org' target='_blank'>info@1degree.org</a></p>",
  'I have already lost my housing, how can I find help now?': 'Ya perdí mi vivienda, ¿cómo puedo encontrar ayuda ahora?',
  'If you lost your housing, are staying with friends/family or in a vehicle':
    "Si perdió su vivienda, se está quedando con amigos/familiares o en un vehículo, puede ser elegible para recibir asistencia para personas sin hogar. <a href='https://www.1degree.org/collections/10376-bay-area-coordinated-entry-access-points-san-francisco-bay-area-california-usa' target='_blank'>Haga clic aquí {{popoutIcon}}</a> para obtener ayuda, o también puede llamar al 211 para asistencia.",
  'I need to find a new place to live. How do I find an affordable apartment or get on a waitlist for one?':
    'Necesito encontrar un nuevo lugar para vivir. ¿Cómo encuentro un apartamento asequible o me inscribo en una lista de espera para obtener uno?',
  'To find affordable housing, please visit':
    "Para encontrar viviendas asequibles, visite el <a href='https://housingbayarea.mtc.ca.gov/' target='_blank'>Doorway Housing Portal {{popoutIcon}}</a>",
  'Is the information I enter on this site secure? Do you ever save or share it?':
    '¿La información que ingreso en este sitio es segura? ¿Alguna vez la guardan o comparten?',
  'Your answers to questions are confidential and will not be saved':
    '<p>Sus respuestas a las preguntas son confidenciales y no se guardarán ni compartirán. Crear una cuenta es opcional, por lo que no necesita proporcionar ninguna información personal para ver los recursos a través de Stay Housed Bay Area. Si proporciona información personal o guarda su información en este sitio web, nunca la compartiremos sin su permiso.</p><p>Hacemos un seguimiento del comportamiento del usuario en general para comprender cómo las personas utilizan el sitio y cómo podemos mejorarlo, pero no utilizamos ninguna información personal para hacerlo.</p><p>Si crea una cuenta, podemos comunicarnos con usted para obtener más información sobre su experiencia de usuario.</p>',
  'What features are available if I create a free One Degree account for myself?':
    '¿Qué funciones están disponibles si creo una cuenta gratuita en One Degree?',
  'If you create a free One Degree account, you will have a private':
    '<p>Si crea una cuenta gratuita en One Degree, tendrá un portal privado y protegido para guardar recursos, crear listas de referencias para clientes y más. Esto puede ser especialmente útil si no puede o no desea acceder y almacenar copias de sus documentos e información.</p><p>Crear una cuenta hace que sea más fácil guardar sus recursos, pero no es obligatorio. Su información personal no será compartida sin su consentimiento.</p>',
  'Who funds and runs Stay Housed Bay Area?': '¿Quién financia y dirige Stay Housed Bay Area?',
  'This website was created by All Home and One Degree':
    'Este sitio web fue creado por All Home y One Degree, con el apoyo y asesoramiento de Health Leads. All Home es una organización del Área de la Bahía que brinda soluciones que rompen con los ciclos de pobreza y falta de vivienda, reducen las disparidades raciales y crean más oportunidades de movilidad económica para personas con ingresos extremadamente bajos. Obtenga más información en la <a href="/stayhousedbayarea/about">página Acerca de</a>.',
  'All Home | About': 'Todo Inicio | Acerca de',
  'All Home | Helpful Links': 'Todo Inicio | Enlaces útiles',
  'Stay Housed Bay Area Launch Team': 'Equipo de lanzamiento de Stay Housed Bay Area',
  'Stay Housed Bay Area is a free, user-friendly website':
    '<p>Stay Housed Bay Area es un sitio web gratuito y fácil de usar donde los residentes de los nueve condados del Área de la Bahía pueden buscar y acceder a recursos para obtener la ayuda que necesitan para conservar su vivienda. Estos recursos pueden ayudarlo a <b>pagar su alquiler, responder a un aviso de desalojo o hacer valer sus derechos para poder permanecer en su vivienda actual.</b></p><p>Este sitio NO está diseñado para proporcionar listados de viviendas de alquiler asequibles o disponibles, <a href="/stayhousedbayarea/assessment#affordable-housing" target="_blank">puede encontrar esos recursos aquí {{popoutIcon}}</a>.</p><p>Este sitio web fue creado por All Home y One Degree, con el apoyo y asesoramiento de Health Leads.</p><p><b>¿Quiere compartir el sitio con sus redes?</b><br><a href="/stayhousedbayarea/sitebadge">Consulte nuestro kit de herramientas de comunicación y gráficos para su sitio web.</a></p>',
  'Who We Are': 'Quiénes somos',
  'All Home is a Bay Area organization advancing solutions that disrupt the cycles of poverty and homelessness':
    "<p><a href='https://www.allhomeca.org/' target='_blank'><b>All Home {{popoutIcon}}</b></a> es una organización del Área de la Bahía que brinda soluciones que rompen con los ciclos de pobreza y falta de vivienda, reducen las disparidades raciales y crean más oportunidades de movilidad económica para personas con ingresos extremadamente bajos.</p>",
  'One Degree brings life-changing social services within reach for the millions':
    "<p><a href='https://about.1degree.org/' target='_blank'><b>One Degree {{popoutIcon}}</b></a> pone servicios sociales que cambian vidas al alcance de millones de personas de bajos ingresos que buscan ayuda todos los días. A través de tecnología fácil de usar y una profunda colaboración con la comunidad, elimina las enormes barreras que impiden que las personas obtengan todo, desde vivienda hasta atención médica y más.</p>",
  'Health Leads is an innovation hub that seeks to unearth and address':
    "<p><a href='https://healthleadsusa.org/' target='_blank'><b>Health Leads {{popoutIcon}}</b></a> es un centro de innovación que busca descubrir y abordar las profundas raíces sociales de la desigualdad racial que afectan nuestra salud. Hoy en día, Health Leads trabaja tanto a nivel nacional como local, en todo Estados Unidos, para crear asociaciones y rediseñar sistemas para que cada persona, en cada comunidad, pueda vivir con salud, bienestar y dignidad.</p>",
  'Questions?': '¿Preguntas?',
  'Questions about the website? Check the FAQ page or':
    "<p>Consulte la <a href='/stayhousedbayarea/faqs'>página de preguntas frecuentes</a> o envíenos un correo electrónico a <a href='mailto:help@1degree.org' target='_blank'><b>help@1degree.org</b></a>.</p><p><i>Tenga en cuenta que nuestro personal no puede resolver ni abordar los desafíos de vivienda de las personas de forma directa. Si necesita hablar con un profesional, utilice los recursos de este sitio o llame al 211.</i></p>",
  'Below are resources that provide information about how you can stay in your home':
    'A continuación encontrará recursos que brindan información sobre cómo puede permanecer en su hogar y comprender mejor sus derechos. A diferencia de los recursos que encontrará con la herramienta de búsqueda Stay Housed Bay Area, estos enlaces no brindan asistencia legal o financiera directa. Son solo informativos.',
  'Tenant Power Toolkit': "<a href='https://tenantpowertoolkit.org/' target='_blank'>Kit de herramientas para inquilinos {{popoutIcon}}</a>",
  'Features a detailed guide to help tenants understand and respond to the eviction process':
    'Incluye una guía detallada para ayudar a los inquilinos a comprender y responder al proceso de desalojo, e información sobre los derechos y la organización de los inquilinos.',
  'Legal Help FAQs': "<a href='https://legalfaq.org/' target='_blank'>Preguntas frecuentes sobre ayuda legal {{popoutIcon}}</a>",
  'Allows tenants to search for local legal rules about housing, eviction, rent, and':
    'Inquilinos pueden buscar las normas legales locales sobre vivienda, desalojo, alquiler y cuestiones entre arrendadores e inquilinos. También puede encontrar grupos y guías para ayudar con la asistencia de vivienda.',
  'Tenants Together’s Resource Directory':
    "<a href='https://www.tenantstogether.org/resource-directory' target='_blank'>Directorio de recursos de Tenants Together {{popoutIcon}}</a>",
  'Has a statewide list of tools to defend and advance the rights of California tenants':
    "Una lista estatal de herramientas para defender y promover los derechos de los inquilinos de California a una vivienda segura, decente y asequible. Una de estas herramientas es una <a href='https://www.tenantstogether.org/resources/list-rent-control-ordinances-city' target='_blank'>lista de ciudades de California {{popoutIcon}}</a> que tienen normas de control de alquileres.",
  'Tenant Protections': "<a href='https://tenantprotections.org/' target='_blank'>Protecciones para inquilinos {{popoutIcon}}</a>",
  'Provides renters in California with high quality information about their rights under':
    'Proporciona a los inquilinos de California información de alta calidad sobre sus derechos según las leyes de California y las normas locales. Cuenta con una herramienta para determinar si está cubierto por las leyes de protección de inquilinos y si un aumento de alquiler es legal o no.',
  'Trouble with your landlord?': '¿Problemas con su arrendador?</br>¿Atrasado en el alquiler?</br>¿En riesgo de desalojo?',
  'We all need help sometimes.': "<b style='font-weight: 600'>Todos necesitamos ayuda de vez en cuando.<br>Hay recursos disponibles.</b>",
  'Find a resource that meets your needs': 'Encuentre un recurso que satisfaga sus necesidades',
  'No sign up or account required':
    '<b>* No necesita registrarse ni tener una cuenta.</b> Su información personal no se guardará ni se compartirá sin su consentimiento.',
  'Stay Housed Bay Area connects people':
    '<b>Stay Housed Bay Area</b> conecta a las personas con la ayuda que necesitan para conservar su vivienda, como asistencia de alquiler y servicios legales.',
  'Brought to you by': 'Presentado por',
  'Do you need a place to live right now?': '¿Necesita un lugar para vivir ahora mismo?',
  'Have you lost your housing, and/or are you staying with friends, family or in a vehicle':
    '¿Ha perdido su vivienda y/o se está quedando con amigos, familiares o en un vehículo? Puede ser elegible para recibir asistencia para personas sin hogar. Este sitio no se enfoca en esa situación, pero puede comenzar aquí para obtener ayuda:',
  'Get help now': 'Obtenga ayuda ahora',
  "If you have lost your housing and don't have a place to stay, contact your county":
    'Si ha perdido su vivienda y no tiene un lugar donde quedarse, comuníquese con su condado lo antes posible para obtener asistencia de vivienda.',
  'Find by County': 'Buscar por condado',
  'Call 211': 'Llame al 211',
  'Call 211 for assistance': 'Llame al 211 para obtener ayuda',
  'Trained operators can assist you to find resources such as short-term shelters and more.':
    'Los operadores capacitados pueden ayudarlo a encontrar recursos como refugios temporales y más.',
  'How to use this site': 'Cómo utilizar este sitio',
  'Tell us about your needs': 'Cuéntenos sobre sus necesidades',
  'Answer a few simple questions about your housing situation': 'Responda algunas preguntas sencillas sobre su situación de vivienda',
  'See your resources': 'Vea sus recursos',
  'Get a list of recommended resources and service providers in your area, tailored to your needs':
    'Obtenga una lista de recursos y proveedores de servicios recomendados en su área, según sus necesidades',
  'Connect with your resources': 'Conéctese con sus recursos',
  'Reach out to your recommended service providers to get the support you need to stay in your home':
    'Comuníquese con sus proveedores de servicios recomendados para obtener el apoyo que necesita para permanecer en su hogar',
  'It looks like you are outside the Bay Area': 'Parece que estás fuera del Área de la Bahía',
  'Stay Housed Bay Area serves the nine counties of the San Francisco Bay Area':
    'Stay Housed Bay Area ofrece servicios a los nueve condados del Área de la Bahía de San Francisco (Alameda, Contra Costa, Marin, Napa, San Francisco, San Mateo, Santa Clara, Solano y Sonoma). Si se encuentra fuera de estos condados y necesita asistencia para la vivienda, puede buscar recursos en <a href="https://www.1degree.org">One ​​Degree.</a>',
  'Do you live in California outside of the Bay Area?': '¿Vives en California fuera del Área de la Bahía?',
  'Below is a Collection of housing resources available anywhere in California. You will be redirected to the One Degree website.':
    'A continuación encontrará una Colección de recursos de vivienda disponibles en cualquier lugar en California. Serás redirigido al sitio web de One Degree.',
  'Stay Housed - California Resources': 'Permanezca Alojado - Recursos en California',
  'Read more': 'Leer más',
  'Show less': 'Mostrar menos',
  'Please enter a more specific location. Try entering an address, city, or zip code.':
    'Por favor ingrese una ubicación más específica. Intente ingresar una dirección, la ciudad o el código postal.',
  'All Home | Share Stay Housed Bay Area': 'All Home | Compartir Stay Housed Bay Area',
  'Share Stay Housed Bay Area': 'Compartir Stay Housed Bay Area',
  'Share SHBA': 'Compartir SHBA',
  'Outreach Toolkit': 'Outreach Toolkit',
  'Site Badge': 'Insignia del sitio',
  'Use this Stay Housed Bay Area Outreach Toolkit to share about the new website on social media':
    'Utilice este kit de alcance sobre Stay Housed Bay Area para compartir el nuevo sitio web en las redes sociales, en el próximo correo electrónico de su organización o con su comunidad. El kit de alcance incluye gráficos, publicaciones de muestra, boletines y contenido web, materiales imprimibles y más.<br><a href="https://docs.google.com/document/d/1ZlPXWi_QsoAvHheWIul3hHTjmBHXeLvrbCe5e5cIQEg/edit?usp=sharing" target="_blank">Outreach Toolkit (Google Drive)</a>',
  'Add a Stay Housed Bay Area badge to your website so your community can easily find resources to help them keep their housing':
    '<p>Agregue una insignia Stay Housed Bay Area a su sitio web para que su comunidad pueda encontrar fácilmente recursos que les ayuden a conservar su vivienda.</p><p><b>Opción 1:</b> Copie y pegue un código de inserción y colóquelo en su sitio web.<br><b>Opción 2:</b> <a href="https://s3.us-west-1.amazonaws.com/odas.production/allHome/badges/badges.zip" target="_blank">Descargar todas las insignias (ZIP)</a></p>',
  'Paste this code:': 'Pega este código:',
  'Recommended Resource': 'Recurso recomendado',
  'This resource was recommended by our team because it currently serves many people in your county.':
    'Este recurso fue recomendado por nuestro equipo porque actualmente presta servicios a muchas personas en su condado.'
};

const translations = {
  en: Object.assign({}, en, properties, tags, ui),
  es: Object.assign({}, es, es_properties, es_tags, es_ui)
};

const t = (text, vars = null) => {
  // This will check if the translation object above contains a userType translation
  // for the userType currently set in the StateContext. The StateContext passes through `vars` param from wherever it is required.
  if (vars?.answers?.userType && translations[lang.getCurrentLanguage()][vars?.answers?.userType][text]) {
    return lang.t(translations[lang.getCurrentLanguage()][vars?.answers?.userType], text, vars);
  }
  // If there is a userType set but the requested translation is not conditional on userType,
  // then just translate it.
  return lang.t(translations[lang.getCurrentLanguage()], text, vars);
};

export default t;
